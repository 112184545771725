import React from "react"
import Layout from "../../containers/Layout"

export default () => (
  <Layout title="Angebot #20200807 »Mitfahrer:in bei einer Radtour«">
    <section>
      <p>
        Als ambitionierte Tourenradler fahren wir alljährlich Radtouren von über
        2.000 Kilometern Länge in Deutschland, Frankreich, Österreich, Italien,
        Schweden und Norwegen. Wir sind stets bestrebt unsere Reichweite noch um
        weitere Länder auszubauen und so den ganzen Kontinent zu erfahren. Dabei
        sind uns Kultur- und Natur-Erfahrung ebenso wichtig wie menschliche und
        persönliche Erlebnisse. Von anspruchsvoller Berpassage zu entspannter
        Flusslandschaft - wir erkunden und wertschätzen alle Szenerien und
        finden Freude egal wo wir sind. Für all unsere Projekte suchen wir
        Mitfahrer:innen, die bereit sind sich den Herausforderungen mehrwöchiger
        Radtouren zu stellen. Dabei gilt es nicht nur sportliche, sondern auch
        menschliche Herausforderungen gekonnt zu bewältigen. Unterstütze unser
        Team auf dem Weg zu neuen Zielen, wie etwa Messina, Härlöv, Barcelona,
        Stockholm, Florenz, Málaga, Neapel, Zagreb, Kopenhagen, oder Marseille.
      </p>
    </section>
    <section>
      <h2>Deine Aufgaben</h2>
      <p>
        Als Mitfahrer:in bei einer Radtour kannst Du Ausdauer, Geduld und
        fahrrad-technisches Know-how zusammenbringen, um mithilfe der
        eingeschränkten Möglichkeiten beim Radfahren die Qualitäten unterwegs zu
        verbessern und die Radtour zu einem genussreichen Erfolg werden zu
        lassen.
      </p>
      <p>
        Du willst bei der Gestaltung einer Radtour, ihren Zielen, und ihrer
        Gestaltung mitwirken und Deine Expertise zu Routenführung oder
        Ausrüstung stetig weiterentwickeln? Dann kannst Du Dich hier einbringen:
        <ul>
          <li>
            Zusammen mit Deinem Team berätst Du über den Reisezeitraum, mögliche
            Ziele, und Dauer von Aufenthalten.
          </li>
          <li>
            Dabei berätst Du auch über notwendige Ausrüstung, die Aufteilung
            dieser auf die Mitfahrer:innen, und organisierst gegebenenfalls
            fehlende Ausrüstungsgegenstände.
          </li>
          <li>
            Du definierst Leistungsmodelle als Basis zur Planung und
            Aufbereitung der o.g. Themen.
          </li>
          <li>
            Darüber hinaus unterstützt Du das Team auch während der Durchführung
            des Projektes bei den anfallenden Aufgaben, wie beispielsweise
            Kochen, reparieren beschädigter Räder, und Navigieren.
          </li>
          <li>
            Du arbeitest aktiv an einer guten Stimmung unter den Teilnehmenden
            mit. Dazu gehören etwa rücksichtsvoller Umgang oder die Gestaltung
            von Abendprogrammen.
          </li>
        </ul>
      </p>
    </section>
    <section>
      <h2>Dein Profil</h2>
      <p>
        <ul>
          <li>
            Mitfahrer:in bei einer Radtour wirst Du bei uns mit einem
            abgeschlossenen Studium der Fahrrad-Wissenschaften, mehrjähriger
            Praxiserfahrung, einem Radtouren-Diplom oder vergleichbaren,
            anerkennbaren Auszeichnungen.
          </li>
          <li>
            Deinen Studienschwerpunkt hast Du auf mehrwöchige
            Langstrecken-Touren in kleinen Teams gelegt.
          </li>
          <li>
            Du bringst relevante Praxiserfahrung im Rahmen von eigenen Touren
            mit. Erwartet werden mindestens drei Touren von je mindestens 1.500
            Kilometern Länge oder einer Dauer von mehr als 4 Wochen.
          </li>
          <li>
            Du hast Interesse an Routengestaltung und Navigation in der
            Landschaft und bringst dafür die nötigen Orientierungsfähigkeiten
            mit.
          </li>
          <li>
            Das Planen und Packen von Ausrüstung sowie das Reparieren von
            Fahrrädern sind für Dich kein komplettes Neuland.
          </li>
          <li>
            Deutsch beherrschst Du verhandlungssicher und Du besitzt die im
            Wasser-Erfragen notwendigen Englisch-Kenntnisse.
          </li>
          <li>
            Eine Hohe Toleranz gegenüber Widrigkeiten (wie Regen, Kälte,
            Übellaunigkeit, …) und ein exzellentes Kommunikationsverhalten
            werden vorausgesetzt.
          </li>
        </ul>
      </p>
    </section>
    <section>
      <h2>Deine Benefits</h2>
      <p>
        <ul>
          <li>
            <strong>Willkommen im Team</strong> Damit Du von Beginn an voll
            involviert bist, gestalten wir gemeinsam mit dir die Route und
            Ausstattung während der gesamten Planungsphase. Zudem fördern wir
            gezielt Deine fachliche und persönliche Integration ins Team.
          </li>
          <li>
            <strong>Vielfältige Tourenperspektiven</strong> Wir legen Wert auf
            die individuelle Entwicklung Deiner fachlichen und persönlichen
            Stärken: Deine eigenen Ambitionen reflektieren wir in strukturierten
            Entwicklungsgesprächen. Systematische und regelmäßige
            Feedback-Gespräche unterstützen Dich beim Erreichen deiner Ziele.
            Als Teil der weltweiten Rad-Community bieten wir Dir interessante
            Reisemöglichkeiten im In- und Ausland und somit wertvolle Einblicke
            in die Vielfalt unserer Landschaften und Städte.
          </li>
          <li>
            <strong>Vereinbarkeit von Beruf und Privatleben</strong> Wir sind
            überzeugt, dass es vor allem Vielfalt und Flexibilität braucht, um
            Innovationen zu fördern und langfristige Erfolge zu sichern. Nur so
            können wir die besten Ideen entwickeln und wirklich innovative
            Lösungen finden. Mit regelmäßigem Ausbrechen aus dem Alltags-Trott,
            der Nähe zur Natur, und wertvollen menschlichen Erfahrungen schaffen
            wir die Basis, um vor dem Berufseinstieg noch einmal etwas zu
            erleben. Darüber hinaus unterstützen wir Dich indem wir bei
            Finanz-Knappheit die Kosten der Tour vorschießen.
          </li>
          <li>
            <strong>Fort- & Weiterbildung</strong> Durch regelmäßige Trainings
            und die menschlichen Herausforderungen einer Radtour entwickelst Du
            Dich stetig weiter. Zudem fördern wir das Ausloten deiner Grenzen
            und den Umgang mit diesen. Auf unseren Touren ermöglichen wir Deinen
            fachübergreifenden Einsatz.
          </li>
        </ul>
        Die abgebildeten Leistungen können je nach Position und Standort leicht
        variieren.
      </p>
    </section>
    <section>
      <h2>Dein Kontakt zu uns</h2>
      <p>
        Bei unseren Touren arbeiten wir an einer bedeutsamen Schnittstelle von
        Natur-Kultur-Erfahrung, Körper-Belastung und geistigen
        Herausforderungen. Deshalb suchen wir Menschen, die mit uns gemeinsam
        diesen begegnen und sie bewältigen möchten. Informiere Dich jetzt über
        Deine Einstiegs- und Entwicklungsmöglichkeiten und die Themen, die
        diskutiert werden müssen.
      </p>
      <p>
        Wir halten die mit »:« gegenderte Form von Stellen-Namen für gut lesbar
        und haben daher versucht im Text zu gendern. Dessen ungeachtet beziehen
        sich die Angaben auf Angehörige aller Geschlechter.
      </p>
      <p>
        Werde Teil unseres Teams und lass uns gemeinsam unsere nächste Tour
        angehen. Wir freuen uns auf Deine Chat-Bewerbung oder ein persönliches
        Gespräch.
      </p>
    </section>
  </Layout>
)
